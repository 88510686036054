import { dateToString } from "@babitalk/utils";
import { createSlice } from "@reduxjs/toolkit";

const today = new Date();
const startDate = new Date();
startDate.setMonth(startDate.getMonth() - 1);

const initialState = {
  list: [],
  paginate: {},
  search: {
    selectType: 0,
    type: 0,
    subtype: 0,
    keyword: "",
    startDate: dateToString(startDate),
    endDate: dateToString(today),
    selectTypeOptions: [
      { name: "상담 전체", value: "0" },
      { name: "이벤트 신청", value: "1" },
      { name: "이벤트 신청(카톡상담)", value: "3" },
      { name: "전화 신청", value: "2" },
      { name: "모델모집 신청", value: "7" },
      { name: "의사상담 신청", value: "8" },
      { name: "병원상담 신청", value: "9" },
    ],
  },
};

export const name = "ask";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    getAsks: () => {},
    setAsks: (state, action) => {
      state.list = action.payload.list;
      state.paginate = action.payload.paginate;
    },
    setDate: (state, { payload }) => {
      state.search[payload.name] = payload.value;
    },
    setType: (state, { payload }) => {
      const selectType = Number(payload);

      state.search.selectType = selectType;
      state.search.type = selectType === 2 ? 1 : selectType;
      state.search.subtype = selectType === 2 ? 2 : 1;
    },
    setKeyword: (state, { payload }) => {
      state.search.keyword = payload;
    },
    toggleStatus: (state, { payload }) => {
      const ask = state.list.find(ask => ask.id === payload);
      if (ask) {
        ask.status = (ask.status + 1) % 2;
      }
    },
    postRecharge: () => {},
    setRecharge: (state, { payload }) => {
      const { id, status, tr_code } = payload;
      const ask = state.list.find(ask => ask.id === id);
      if (ask) {
        if (tr_code === 23 || (tr_code !== 23 && status === 9)) ask.phone = "";
        ask.status = status;
        ask.tr_code = tr_code;
      }
    },
    addMessageCount: (state, { payload }) => {
      const ask = state.list.find(ask => ask.id === payload);
      if (ask) {
        ask.smscnt++;
      }
    },
    postMemo: () => {},
    setMemo: (state, action) => {
      const ask = state.list.find(ask => ask.id === action.payload.id);
      if (ask) {
        ask.memo = action.payload.memo;
        if (ask.status === 0) ask.status = 1;
      }
    },
    sendMessage: () => {},
    getMessageList: () => {},
    exportExcel: () => {},
    setInitialState: () => {
      return initialState;
    },
  },
});

export const reducer = slice.reducer;
export const action = slice.actions;
