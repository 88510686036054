import { Button, Typography } from "@mui/material";
import React from "react";
import Symbol from "../Icons/Symbol";
import Modal from "../Modal";
import { Buttons, Content, Title, Wrapper } from "./styled";

const AlertCustomModal = ({ options, close }) => {
  const { title, text, icon, button, width, mode } = options;

  const buttonConfigs = {
    danger: [
      {
        color: "danger",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
      {
        color: "secondary",
        variant: "outlined",
        text: button?.cancel?.text || "취소",
        onClick: close,
      },
    ],
    default: [
      {
        color: "secondary",
        variant: "outlined",
        text: button?.cancel?.text || "취소",
        onClick: close,
      },
      {
        color: "primary",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
    ],
    check: [
      {
        color: "primary",
        variant: "contained",
        text: button?.confirm?.text || "확인했어요",
        onClick: () => close("confirm"),
      },
    ],
  };

  const renderButtons = () => (
    <Buttons direction="horizontal">
      {buttonConfigs[mode]?.map(({ color, variant, text, onClick }, index) => (
        <Button key={index} color={color} variant={variant} size="large" onClick={onClick}>
          {text}
        </Button>
      ))}
    </Buttons>
  );

  return (
    <Modal width={width ?? 400} hideCloseButton>
      <Wrapper>
        <Content>
          {icon && (
            <Symbol
              name={icon?.name || icon || "warning"}
              variant="contained"
              color={icon?.color || "grey500"}
              fontSize="large"
            />
          )}
          <Title>{title}</Title>
          <Typography variant="body1" color="text.grey700">
            {text}
          </Typography>
        </Content>
        {renderButtons()}
      </Wrapper>
    </Modal>
  );
};

export default AlertCustomModal;
